import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import { graphql } from "gatsby"

const PoisonCenter = ({ data: { allNodeTemplatePages: { edges } } }) => {

    const rawData = edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData.node?.metatags_on_page || {}
    const data = rawData?.node?.relationships?.components;
    const [bannerData, accordianData] = data
    
    const bannerImg = typeof (bannerData?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url) !== "undefined" ? bannerData?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url : "/assets/images/patient_care/banner_patient_care.jpg"
    const mobileBannerImg = bannerData?.relationships?.components[0]?.relationships?.mobileBannerImage?.uri?.url || bannerImg;
    const bannerAlt = typeof (bannerData?.relationships?.components[0]?.bannerImage?.alt) !== "undefined" && bannerData?.relationships?.components[0]?.bannerImage?.alt?.length > 0 ? bannerData?.relationships?.components[0]?.bannerImage?.alt : "Hinduja Hospital"
    const bannerTitle = typeof (bannerData?.relationships?.components[0]?.title) !== "undefined" && bannerData?.relationships?.components[0]?.title?.length > 0 ? bannerData?.relationships?.components[0]?.title : "";
    const bannerSubtitle = typeof (bannerData?.relationships?.components[0]?.subtitle) !== "undefined" && bannerData?.relationships?.components[0]?.subtitle?.length > 0 ? bannerData?.relationships?.components[0]?.subtitle : "";

    const accordian = accordianData?.relationships?.components;

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/research.css", "/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage pt-first-section">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                          <img src={mobileBannerImg} alt={bannerAlt} className="d-md-none"/>
                          <img src={bannerImg} alt={bannerAlt} className="d-md-block d-none"/>
                        </picture>
                        <div className="banner_content text_left text_dark">
                          <div className="container">
                            <h1>{bannerTitle}</h1>
                            <p>{bannerSubtitle}</p>
                          </div>
                        </div>
                    </section>
                    {/* {(content && content?.relationships?.components) && */}
                        <section className="services_section common_services section-bg section-py">
                            <div className="container">
                                {/* {content?.relationships?.components.map(c => {
                                    return(
                                        <div dangerouslySetInnerHTML={{ __html: c?.htmlText?.processed }}></div>
                                    )
                                })} */}
                                <div className="accordion_container">
                                    <div id="accordion" role="tablist" className="common_accordion specialty_accordion">
                                        {
                                        accordian.map((el, index) => {
                                            if (el.__typename === "paragraph__image_with_title_and_description") {

                                            return (
                                                <div className="card" key={index}>
                                                <div className="card-header" role="tab" id={`heading_inpatient0${index + 1}`}>
                                                    <h5 className="mb-0">
                                                    <a className="" data-toggle="collapse" href={`#outpatient_0${index + 1}`} aria-expanded="false" id={index + 1}>
                                                        {el?.title}
                                                    </a>
                                                    </h5>
                                                </div>
                                                <div id={`outpatient_0${index + 1}`} className="collapse show" role="tabpanel" aria-labelledby="heading_inpatient01" data-parent="#accordion">
                                                    <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                        <figure>
                                                            <img src={el?.relationships?.image?.uri?.url} alt={el?.image?.alt} />
                                                        </figure>
                                                        </div>
                                                        <div className="col-md-6" dangerouslySetInnerHTML={{ __html: el?.description?.processed }} />


                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: el?.field_text?.processed }} />
                                                    <figure>
                                                        <img src={el?.relationships?.image?.uri?.url} alt={el?.image?.alt} />
                                                    </figure>

                                                    </div>
                                                </div>
                                                </div>
                                            )
                                            } else {
                                            return (
                                                <div className="card" key={index}>
                                                <div className="card-header" role="tab" id={`heading_inpatient0${index + 1}`}>
                                                    <h5 className="mb-0">
                                                    <a className="collapsed" data-toggle="collapse" href={`#outpatient_0${index + 1}`} aria-expanded="false" id={index + 1} >
                                                        {el?.field_title}
                                                    </a>
                                                    </h5>
                                                </div>
                                                <div id={`outpatient_0${index + 1}`} className="collapse" role="tabpanel" aria-labelledby="heading_inpatient02" data-parent="#accordion">
                                                    <div className="card-body" dangerouslySetInnerHTML={{ __html: el?.text?.processed }}>
                                                    </div>
                                                </div>
                                                </div>
                                            )
                                            }
                                        })

                                        }

                                    </div>
                                </div>
                            </div>
                        </section>
                    {/* } */}
                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query poisonCentre {

  allNodeTemplatePages(filter: {path: {alias: {regex: "/poison-centre$/"}}}) {

    edges {

      node {

        id

        title

        path {

          alias

        }
        relationships{
          field_seo_schema{
              field_seo_title
              field_seo_schema_text_area
          }
        }
        metatag_normalized {

          attributes {

            content

            name
            property

          }

        }
        metatags_on_page: field_meta_tags{
          canonical_url
        }
        relationships {

          components: field_component_type {

            __typename

            ...ParagraphSectionPC

          }

        }

      }

    }

  }

}

 

fragment ParagraphSectionPC on paragraph__section {

  id

  name: field_name

  relationships {

    components: field_components {

      __typename

      ...ParagraphBannerPC

      ...ParagraphTitleAndDescriptionPC

    }

  }

}

 

fragment ParagraphBannerPC on paragraph__banner {

  id

  title: field_title

  subtitle: field_subtitle

  bannerImage: field_banner_image {

    alt

  }

  mobileBannerImage: field_mobile_banner {

    alt

  }

  bannerLink: field_banner_link {

    uri

    title

  }

  relationships {

    bannerImage: field_banner_image {

      id

      uri {

        value

        url

      }

    }

    mobileBannerImage: field_mobile_banner {

      id

      uri {

        url

        value

      }

    }

  }

}

 

fragment ParagraphTitleAndDescriptionPC on paragraph__title_and_description {

  id

  field_title

  text: field_description {

    processed

  }

  field_cta_link {

    title

    uri

  }

  field_text {

    processed

  }

}
`

export default PoisonCenter